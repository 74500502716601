:root {
    // Cloned default hex values from base/css_variables to be used in the system console.
    --sys-away-indicator: #ffbc1f;
    --sys-away-indicator-dark: #997113;
    --sys-button-bg: #166de0;
    --sys-button-color: #fff;
    --sys-center-topic-bg: #fff;
    --sys-center-topic-color: #3d3c40;
    --sys-dnd-indicator: #f74343;
    --sys-error-text: #d24b4e;
    --sys-link-color: #2389d7;
    --sys-mention-bg: #fff;
    --sys-mention-color: #145dbf;
    --sys-mention-highlight-bg: #ffe577;
    --sys-mention-highlight-link: #166de0;
    --sys-new-message-separator: #f80;
    --sys-online-indicator: #3db887;
    --sys-sidebar-bg: #145dbf;
    --sys-sidebar-header-bg: #1153ab;
    --sys-sidebar-header-text-color: #fff;
    --sys-sidebar-text: #fff;
    --sys-sidebar-text-60: #fff9;
    --sys-sidebar-text-80: #fffc;
    --sys-sidebar-text-active-border: #5d89ea;
    --sys-sidebar-text-active-color: #fff;
    --sys-sidebar-text-hover-bg: #4578bf;
    --sys-sidebar-unread-text: #fff;
    --sys-denim-button-bg: #1c58d9;
    --sys-denim-center-topic-text: #3f4350;

    // Cloned RGB values from base/css_variables, to be used as rgba(var(--sys-color), opacity_here) to apply opacity to base colors
    --sys-away-indicator-rgb: 255, 188, 31;
    --sys-away-indicator-dark-rgb: 153, 113, 19;
    --sys-button-bg-rgb: 22, 109, 224;
    --sys-button-color-rgb: 255, 255, 255;
    --sys-center-topic-bg-rgb: 255, 255, 255;
    --sys-center-topic-color-rgb: 61, 60, 64;
    --sys-dnd-indicator-rgb: 247, 67, 67;
    --sys-error-text-color-rgb: 210, 75, 78;
    --sys-link-color-rgb: 35, 137, 215;
    --sys-mention-bg-rgb: 255, 255, 255;
    --sys-mention-color-rgb: 20, 93, 191;
    --sys-mention-highlight-bg-rgb: 255, 229, 119;
    --sys-mention-highlight-link-rgb: 22, 109, 224;
    --sys-new-message-separator-rgb: 255, 136, 0;
    --sys-online-indicator-rgb: 61, 184, 135;
    --sys-sidebar-bg-rgb: 20, 93, 191;
    --sys-sidebar-header-bg-rgb: 17, 83, 171;
    --sys-sidebar-header-text-color-rgb: 255, 255, 255;
    --sys-sidebar-text-rgb: 255, 255, 255;
    --sys-sidebar-text-active-border-rgb: 93, 137, 234;
    --sys-sidebar-text-active-color-rgb: 255, 255, 255;
    --sys-sidebar-text-hover-bg-rgb: 69, 120, 191;
    --sys-sidebar-unread-text-rgb: 255, 255, 255;
    --sys-denim-button-bg-rgb: 28, 88, 217;
    --sys-denim-center-topic-text-rgb: 63, 67, 80;
}