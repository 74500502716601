.AdminSidebarHeader {
    background: #333;

    > div {
        &:first-child {
            position: relative;
            display: flex;
            align-items: center;
            padding: 10px;
            cursor: pointer;
        }

        > img {
            width: 36px;
            height: 36px;
            border-radius: 36px;
        }

        .header__info {
            @include clearfix;

            position: relative;
            width: 100%;
            flex-grow: 1;
            padding: 3px 15px 0 8px;
            color: $white;

            .org__name {
                overflow: hidden;
                max-width: 100%;
                font-size: 14px;
                font-weight: 600;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .user__name {
                font-weight: 400;
                opacity: 0.8;
            }
        }

        .menu-icon {
            margin-right:10px;
            fill: $white;
            opacity: 0.8;
        }

        &:hover {
            .user__name,
            .menu-icon {
                opacity: 1;
            }

            > div > img {
                opacity: 0.8;
            }
        }
    }

    .Menu {
        margin-left: 16px;
    }
}