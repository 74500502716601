@charset 'UTF-8';

b,
string {
    font-weight: 600;
}

a {
    color: $primary-color;
    cursor: pointer;
    text-decoration: none;
    word-break: break-word;

    &:focus,
    &:hover {
        color: $primary-color--hover;
    }
}

.color--link {
    color: $link-color;
    cursor: pointer;

    &:hover, 
    &:focus {
        text-decoration: underline;
    }
}

body {
    @include font-smoothing;

    font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3 {
    font-family: Metropolis, sans-serif;
}

h1 {
    font-weight: 600;
}

.is-empty:empty {
    display:none;
}

label {
    &.has-error {
        color: $red;
        font-weight: normal;
    }
}

.small {
    font-size: 120x;
}

.light {
    opacity: 0.56;
}

.word-break--all {
    word-break: break-all;
}

.whitespace--nowrap {
    white-space: nowrap;
}

.whitespace--normal {
    white-space: normal;
}

.overflow--ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

ul,
ol {
    padding-left: 22px;
    margin-top: 3px;
    margin-bottom: 0.11em;
}

.fa {
    &.fa-1x {
        font-size: 1.6rem;
    }
}

.font-weight--normal {
    font-weight: normal;
}

.font-weight--semibold {
    font-weight: 600;
}

.cursor--pointer {
    cursor: pointer;
}


// Metropolis font definitions
@font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/Metropolis-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Metropolis';
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/Metropolis-SemiBoldItalic.woff') format('woff');
}


@font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Metropolis-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Metropolis';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/Metropolis-RegularItalic.woff') format('woff');
}

@font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Metropolis-Light.woff') format('woff');
}

@font-face {
    font-family: 'Metropolis';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/Metropolis-LightItalic.woff') format('woff');
}

.app__body.font--open_sans {
    font-family: 'Open Sans', sans-serif;
}

.app__body {
    h1, 
    h2, 
    h3 {
        font-family: Metropolis, sans-serif;
    }
}

.color--link-adminack {
    padding-left: 20px;
    color: $white;
    cursor: pointer;
    text-decoration: underline;

    &:hover, 
    &.focus {
        text-decoration: underline;
    }
}