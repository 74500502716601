


.group-workspaces-and-topics {
    .group-workspaces-and-topics-loading {
        padding: 40px;
        text-align: center;
    }

    .group-workspaces-and-topics-empty {
        padding: 20px;
        background: alpha-color($white, 0.5);
        color: $gray;
        font-size: 1.5em;
        text-align: center;
    }

    .group-workspaces-and-topics--header {
        font-size: 1.1em;
        font-weight: bold;
    }

    .group-workspaces-and-topics--body {
        padding: 20px;

        .group-workspaces-and-topics-row.has-children {
            .type {
                margin-left: 20px;
            }
        }

        .group-workspaces-and-topics-row {
            &:nth-child(even) {
                background: alpha-color($white, 0.5);
            }

            .team-icon,
            .channel-icon {
                width: 16px;
                height: 16px;
                margin: 0 7px 0 4px;
                color: inherit;
                opacity: 0.7;
            }

            .arrow-icon {
                .fa {
                    padding: 0 4px;
                    cursor: pointer;
                }
            }

            .channel-icon {
                margin: 1px 2px 0 20px;
            }

            .team-icon-private {
                font-size: 0.35em;
            }

            .actions {
                button {
                    padding: 5px;
                }
            }

            .type {
                flex: 1;
                margin-left: -29px;
                color: #454448;
                opacity: 50%;
            }

            .name-no-arrow {
                margin-left: 16px;
            }

            .name-content {
                display: flex;
                flex: 1;

                .name {
                    flex: 1;
                }

                .arrow-icon {
                    margin-left: 10px;

                    .fa-caret-right {
                        padding: 5px;
                        cursor: pointer;
                    }

                    .open {
                        transform: rotate(90deg);
                        transform-origin: 55% 50%;
                        transition: transform 300ms;
                    }
                }
            }

            .remove {
                height: 30px;
            }
        }

        .workspaces-list {
            .workspaces-list--header {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid $dark-gray;
                font-size: 1.1em;
                font-weight: bold;
            }
        }
    }
}
