


.workspace-info-block {
    display: flex;
    flex-grow: 1;
    align-items: center;

    .workspace-data {
        flex-grow: 1;
        padding: 0 0 0 10px;

        .title {
            font-weight: bold;
        }
    }

    .WorkspaceIcon {
        background: rgba(0, 0, 0, 0.12);
    }
}
