.backstage-navbar {
    z-index: 100;
    height: $backstage-bar-height;
    padding: 10px 20px;
    border-bottom: 1px solid $light-gray;
    background: $white;
    grid-area: header;
}

.backstage-navbar__back {
    color: var(--sys-button-bg);
    text-decoration: none; 
    .fa {
        margin-right: 7px;
        font-size: 1.1em;
        font-weight: bold;
    }

    &:hover, 
    &:active {
        color: var(--sys-button-bg);
        text-decoration: underline;
    }
}