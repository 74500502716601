@charset "utf-8";


.topic-header {
    //for the thread gridview
border-bottom: var(--border);
grid-area: topic-header;

position: relative;
//width: 100%;
max-height: 68px;
flex: 0 0 68px;
border-bottom: 1px solid rgba(var(--center-topic-color-rgb), 0.12);
background: v(center-topic-bg);
font-size: 14px;
  .topic-header__trigger {
    display: flex;
    min-width: 0;
    height: 24px;
    flex: 1 1 0;
    align-items: center;
    padding: 4px 4px;
    border-radius: 4px;

    &:hover {
        background: rgba(var(--center-topic-color-rgb), 0.08);
    }
    &.active {
        background: rgba(v(button-bg-rgb), 0.08);

        .heading, 
        .header-dropdown-chevron-icon {
            color: v(button-bg);
        }
    }

    .heading > span {
        overflow: hidden;
        line-height: 20px;
        text-overflow: ellipsis;
    }
  }

  .icon::before {
    margin-right: 0;
    margin-left: 0;
  }

  .topic-header__favorites {
    position: relative;
    z-index: 1;
    height: 24px;
    padding: 4px;
    border-radius: 4px;
    color: var(--center-topic-color);
    float: left;

    &:hover {
        background: rgba(var(--center-topic-color-rgb), 0.08);
        i {
            opacity: 0.72;
        }
    }

    &.inactive {
        .icon {
            color: v(center-topic-color);
        }
    }

    &.active {
        i {
            opacity: 1;
        }
    }

    i {
        position: relative;
        top: -1px;
        font-size: 18px;
        opacity: 0.56;
    }
  }

  .topic-header__mute {
    position: relative;
    z-index: 1;
    height: 24px;
    padding: 4px;
    margin-left: 4px;
    border-radius: 4px;
    color: v(center-topic-color);
    float: left;

    &:hover {
        background: rgba(v(center-topic-color-rgb), 0.08);

        i {
            opacity: 0.72;
        }
    }

    &.inactive {
        .icon {
            color: v(center-topic-color);
        }
    }
    &.active {
        i {
            opacity: 1;
        }
    }

    i {
        position: relative;
        top: -2px;
        font-size: 16px;
        opacity: 0.56;
    }
  }

  .topic-header__icon {
    line-height: 16px;

    .topic-header__members {
        font-size: 14.4px;
    }

    .topic-header__pink {
        font-size: 14.4px;
    }
  }

  .flex-parent {
    display: flex;
    padding: 0 16px 0 8px;
  }

  .flex-child {
    &:first-child {
        min-width: 0;
        flex: 1;
        padding: 0 28px 0 0;
    }

    .markdown__paragraph-inline {
        display: inline-block;
        overflow: hidden;
        width: 100%;
        margin: 0;
        text-overflow: ellipsis;
    }
  }

  .topic-header_plugin-dropdown {
    min-width: 200px;
    max-width: 300px;
    padding: 0;

    .icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        color: rgba(var(--center-topic-color-rgb), 0.56);
        fill: rgba(var(--center-topic-color-rgb), 0.56);
        font-size: 1.6rem;
        text-align: center;

        svg, 
        img {
            width: 16px;
            height: 16px;
        }
    }

    a {
        padding: 10px 16px;
        border-left: 3px solid transparent;
        background: rgba(var(--sidebar-text-rgb), 0.08);

        &:hover {
            .fa {
                opacity: 1;
            }
            svg {
                opacity: 1;
            }
        }
    }
  }

  .move--left & {
    .icon--hidden {
        display: none;
    }
  }

  &.alt {
    margin: 0;

    th {
        font-weight: normal;
        vertical-align: top;

        &.header-list__right {
            padding-right: 4px;
            white-space: nowrap;
        }
    }
    td {
        border: none;
    }
  }

  .heading {
    overflow: hidden;
    margin: 0 0 0 2px;
    font-family: Metropolis, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-overflow: ellipsis;
  }

  .caret {
    margin-top: 3px;
  }

  .more {
    display: inline-block;
    color: #81848b;
    font-size: 13px;
    text-transform: capitalize;
    vertical-align: top;
  }

  .disabled {
    color: #999;
  }

  .shared-topic-icon {
    width: 16px;
    height: 16px;
    margin: 0 4px;
    font-size: 16px;
    line-height: 16px;

    &::before {
        margin: 0;
    }
  }
}

.row {
    &.header {
        position: relative;

        .topic__wrap & {
            display: none;
        }
    }
}

.header-dropdown__icon {
    position: relative;
    top: 0;
    margin: 0 0 0 4px;
    color: inherit;
    font-size: 16px;
    line-height: 16px;
}

.topic-header__top {
    display: flex;
    min-width: 0;
    align-items: center;
    gap: 4px;
}

.topic-header__bot {
    margin-left: 4px;
}

.topic-header__info {
    margin-top: 8px;
    white-space: nowrap;

    .dropdown-toggle {
        display: flex;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
    }

    .header-dropdown__icon {
        flex: 0 0 auto;
    }

    .topic-header__title {
        display: flex;

        .topic-header__favorites {
            flex: 0 0 auto;
        }

        .topic-header-archived-icon {
            opacity: 0.5;
        }

        > div {
            display: flex;
            min-width: 0;
            flex: 0 1 auto;
        }

        > a {
            display: inline-block;
            width: calc(100% - 65px);
            text-decoration: none;
        }

        .MenuWrapper {
            width: 100%;
        }

        .modal {
            white-space: normal;
        }
    }

    .topic-header__description {
        position: relative;
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 25px;
        align-items: center;
        margin: 0;
        font-size: 12px;
        text-overflow: ellipsis;

        span[data-emoticon] {
            .emoticon {
                position: relative;
                top: -1px;
                overflow: hidden;
            }
        }

        blockquote {
            overflow: hidden;
            padding: 0 0 0 20px;
            font-size: 13px;
            text-overflow: ellipsis;

            &::before {
                top: 0;
                left: 2px;
                font-size: 15px;
            }
        }

        .header-placeholder {
            display: flex;
            overflow: hidden;
            align-items: center;
            margin-left: 4px;
            line-height: 16px;

            span {
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .icon {
                margin-left: 4px;
            }
        }
        .header-description__text {
            width: 500px;
            display: flex;
            overflow: hidden;
            flex-direction: column;
            align-self: start;
            margin-top: 4px;
            margin-left: 4px;
            line-height: 19px;

            ul {
                &:first-child {
                    margin: 0;
                }
            }

            li {
                margin-bottom: 8px;

                p {
                    display: inline;
                    margin: 0;
                }
            }
        }
        .markdown__heading {
            margin: 0;
            font-size: 1em;
        }

        code {
            padding: 2px 5px 0;
            margin: 1px 0 0;
        }

        &.light {
            overflow: visible;
            color: inherit;
            opacity: 1;

            button {
                color: rgba(var(--center-topic-color-rgb), 0.64);

                .edit-icon {
                    display: none;
                }

                &:focus, 
                &:hover {
                    color: rgba(var(--center-topic-color-rgb), 0.72);
                    text-decoration: none;
                }

                &:hover {
                    .edit-icon {
                        display: block;
                    }
                }
            }
        }

        .markdown-inline-img {
            max-height: 45px;
        }

        .edit-icon {
            font-size: 14px;
        }

        .status {
            margin-left: 6px;
            line-height: 16px;
        }
    }
    .has-guest-header {
        &::after {
            position: relative;
            top: 1px;
            margin: 0 2px 0 5px;
            content: "\2022";
        }
    }
    .header-status__text {
        display: flex;
        overflow: hidden;
        align-items: center;
        margin-right: 7px;
        line-height: 16px;

        span.last-active__text {
            color: rgba(var(--center-topic-color-rgb), 0.72);
        }

        .custom-emoji__wrapper {
            padding-left: 8px;
        }
        p {
            margin: 0;
        }
    }

    .status {
        width: auto;
        margin: 0 4px 0 0;

        svg {
            width: 12px;
        }
    }
}

.topic-header__popover, 
.navbar__popover {
    p {
        white-space: pre-wrap;
    }
}

.topic-intro {
    padding: 110px 15px 0;
    margin: 0 auto 10px;

    &.topic-intro--centered {
        max-width: 1020px;
    }

    .intro-links {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        margin-bottom: 10px;

        .fa, 
        i {
            margin-right: 5px;
        }
    }

    .topic-intro-profile {
        margin: 0 0 4px;
        gap: 8px;

        .user-popover {
            overflow: hidden;
            max-width: calc(100% - 100px);
            font-family: Metropolis, sans-serif;
            font-size: 28px;
            font-weight: 600;
            text-overflow: ellipsis;
        }
    }

    .topic-intro-img {
        .status-wrapper {
            height: 50px;
            margin: 0 10px 10px 0;
        }

        img {
            border-radius: 100px;
        }
    }

    .topic-intro__title {
        margin-bottom: 15px;
        font-size: 28px;
        font-weight: 600;
    }

    .topic-intro__content {
        padding: 10px 15px;
        background: $bg--gray;
        border-radius: 3px;
    }
}