@charset 'UTF-8';

.admin-console__wrapper {
    overflow: auto;
}

.admin-console {
    overflow: auto;
    height: 100%;
    color: #333;
    grid-area: center;
    -webkit-overflow-scrolling:touch;

    > div {
        width: 100%;
        height: 100%;
    }
    .wrapper--fixed {
        display: flex;
        height: 100%;
        flex-direction: column;

        // Fix for Safari on iOS
        @supports (-webkit-touch-callout: none) {
            height: -webkit-fill-available;
        }
    }

    .admin-console__wrapper {
        position: relative;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        -webkit-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 20px;
        margin-right: 5px;
        scrollbar-color: var(--center-topic-color-32);
        scrollbar-width: thin;

        > .wrapper--admin {
            display: flex;
            overflow: auto;
            height: 100%;
            flex-direction: column;
        }
    }

    .admin-console__content {
        max-width: 920px;
    }

    mark {
        background-color: $highlight-color;
        border-radius: 5%;
    }

    .btn {
        &.btn-default {
            background: alpha-color($black, 0.7);
            color: $white;

            &:hover,
            &:focus, 
            &:active {
                background: alpha-color($black, 0.8);
                color: $white;
            }
        }
        &.btn-spacing--right {
            margin-right: 10px;
        }

        .fa {
            margin-right: 7px;

            &.margin-left {
                margin-left {
                    margin-right: 0;
                    margin-left: 7px;
                }
            }
        }

        .table {
            margin-bottom: 0;
        }
    }
}


.admin-sidebar {
    z-index: 10;
    display: flex;
    width: 220px;
    flex-direction: column;
    background: #111;
    grid-area: lhs;

    .filter-container {
        position: relative;

        .search__icon {
            position: absolute;
            top: 16px;
            left: 21px;
            width: 16px;
            height: 16px;
            fill: rgba(var(--sys-sidebar-text-rgb), 0.56);
            stroke: #999;
        }

        .filter {
            width: 204px;
            padding: 5px 36px;
            border: 1px solid #666;
            margin: 8px;
            background: rgba(255, 255, 255, 0.05);
            border-radius: 20px;
            color: $white;
            font-size: 14px;
            outline: none;

            &:focus, 
            &.active {
                border: 2px solid $primary-color;
                margin: 7px;
            }
        }

        .input-clear {
            .input-clear-x {
                position: absolute;
                right: 16px;
                color: $white;
                font-weight: bold;
            }
        }
    }

    .Menu {
        .dropdown-menu {
            overflow: auto;
            background: var(--sys-center-topic-bg);

            .MenuGroup.menu-divider {
                background: rgba(var(--sys-center-topic-color-rgb), 0.16);
            }
        }
    }

    mark {
        background-color: $highlight-color;
        border-radius: 5%;
    }

    body.announcement-bar--fixed & {
        top: $announcement-bar-height + $backstage-bar-height;
        padding-bottom: $announcement-bar-height;
    }

    .dropdown-menu {
        overflow: auto;
        min-width: 200px;
        max-width: 200px;
        max-height: 80vh;
    }

    .org__header {
        background: transparent;
    }

    .nav-pills__container {
        @include font-smoothing(initial);

        position: relative;
        height: calc(100% - 68px);
        padding-bottom: 20px;
        margin-top: 1px;

        ul {
            padding-bottom: 20px;
            margin-top: 1px;

            &.nav-stacked.task-list-shown {
                padding-bottom: 72px;
            }
        }
    }

    .sidebar-category {
        margin-top: 0;

        .category-title {
            padding: 10px 12px;
            background: alpha-color($white, 0.15);
            color: $white;
            line-height: 15.4px;
            text-transform: uppercase;

            .category-icon {
                top: 6px;
                overflow: hidden;
                width: 16px;
                margin-right: 6px;
                text-align: center;
                vertical-align: bottom;

                &.fa-users {
                    font-size: 13px;
                }
            }
        }

        .sections {
            padding: 5px 0;
        }
    }

    .sidebar-section {
        > .sidebar-section-title {
            position: relative;
        }
        
        .nav__sub-menu {
            margin-bottom: 7px;

            &:empty {
                display: none;
            }
        }
    }

    .sections {
        &.sections--settings {
            .sidebar-section-title {
                text-transform: uppercase;

                &:hover,
                &:focus {
                    color: alpha-color($white, 0.5);
                }
            }
        }
    }

    .sidebar-section-title {
        padding: 6px 35px 6px 12px;
    }

    .sidebar-section-tag {
        position: relative;
        top: -1px;
        height: 16px;
        padding: 0 4px 0 4px;
        margin-left: 8px;
        background-color: rgba(255, 255, 255, 0.64);
        border-radius: 4px;
        color: rgba(61, 60, 64, 1);
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .sidebar-section-indicator {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;

        i {
            color: alpha-color($white, 0.5);
            font-size: 14px;
            line-height: 14px;
        }
    }

    .sidebar-subsection-title {
        padding: 6px 35px 6px 30px;

        &--active {
            &::after {
                top: 4px;
            }
        }
    }

    sidebar-section-title, 
    .sidebar-subsection-title {
        position: relative;
        display: block;
        color: alpha-color($white, 0.5);
        font-size: 13px;

        &:focus {
            text-decoration: none;
        }

        &:hover {
            color: lighten($primary-color, 10);
            text-decoration: none;

            i {
                color: lighten($primary-color, 10);
            }
        }

        &--active {
            background: alpha-color($white, 0.1);
            color: $white;

            i {
                color: $white;
            }

            &hover, 
            &focus {
                background: alpha-color($white, 0.1);
                color: $white;

                i {
                    color: $white;
                }
            }

            &::after {
                position: absolute;
                top: 2px;
                right: -1px;
                display: inline-block;
                color: whitesmoke;
                content: "\f0d9";
                font: normal normal normal 26px/1 FontAwesome;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                text-rendering: auto;
            }
        }
    }

    .menu-icon--right {
        position: absolute;
        top: 8px;
        right: 12px;
        width: 20px;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        text-align: center;

        .fa {
            position: relative;
            right: -2px;
            color: $white;
            font-size: 13px;
        }

        &.menu__close {
            top: 3px;
            right: 10px;
            cursor: pointer;
        }
    }
}

.system-users__filter-row {
    display: flex;
    margin-right: 15px;
    margin-left: 15px;

    .system-users__filter {
        flex: 1 1 140px;
    }

    .system-users__workspace-filter {
        flex: 1 1 auto;
    }

    > label {
        display: flex;
        flex: 1 1 auto;
        align-items: center;

        span {
            padding: 0 4px 0 8px;
        }
    }

    label {
        font-weight: normal;
    }
}

.manage-workspaces {
    .modal-body {
        padding: 15px 15px 0;
    }

    .manage-workspaces__user {
        display: flex;
        align-items: center;
    }

    .manage-workspaces__workspaces {
        border-top: $border-gray;
        margin: 1em 0 0.3em;
        margin-top: 1em;

        .btn-link {
            &.danger {
                color: #c55151;
            }
        }

        .manage-row__empty {
            padding: 9px 0;
        }
    }

    .member-row--padded {
        padding-left: 20px;

        strong {
            margin-right: 10px;
        }
    }

    .member-row-lone-padding {
        padding-top: 10px;
    }

    .manage-row--inner {
        padding: 15px 0 4px;

        & + div {
            border-top: $border-gray;
        }
    }

    .manage-workspaces__info {
        overflow: hidden;
        flex: 1;
        margin-left: 10px;
        white-space: nowrap;

        .manage-workspaces__name {
            overflow: hidden;
            font-weight: bold;
            text-overflow: ellipsis;
        }

        .manage-workspaces__email {
            overflow: hidden;
            opacity: 0.5;
            text-overflow: ellipsis;
        }
    }

    .manage-workspaces__system-admin {
        padding-right: 10px;
        margin-left: 10px;
        opacity: 0.5;
    }

    .manage-workspaces__workspace {
        display: flex;
        align-items: center;
        padding: 7px 10px;
        border-bottom: $border-gray;

        .btn {
            font-size: 0.9em;
        }

        .dropdown {
            padding: 6px 0;
        }
    }

    .manage-workspaces__workspace-name {
        overflow: hidden;
        flex: 1;
        text-overflow: ellipsis;
    }

    .manage-workspaces__workspace-actions {
        margin-left: 10px;

        // Override default react-bootstrap style
        .dropdown-toggle {
            box-shadow: none;
        }
    }
}
